import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomButton from '../../shared/customButton';
import NotificationCard from '../../shared/notificationCard';
import AlertDialog from '../../shared/alertDialog';
import WarningDialog from '../../shared/warningDialog';
import Search from '../../shared/search';
import GridTable from '../../shared/gridTable';
import DotsMenu from '../../shared/dotsMenu';
import ChooseNameDialog from '../../shared/chooseNameDialog';
import PreviewAttributeDialog from '../../shared/previewAttributeDialog';
import ReadLessMore from '../../shared/readLessMore';
import ActionButton from '../../shared/actionButton';
import Filters from '../../shared/filters';
import ArrowTooltip from '../../shared/arrowTooltip';
import CustomFormDrawer from '../../shared/customFormDrawer';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/content_copy.svg';
import {
  showSuccessMessage,
  parseDuplicateParameters,
  parseQueryParams,
  getPersonalAttributeTooltipText,
} from '../../../utility/uiUtils';
import { hasSelectedFilters } from '../../shared/filters/config';
import http from '../../../utility/http';
import {
  trimString,
  isArrayEmpty,
  isArray,
  checkUserRole,
  getObjectToNumberArray,
  isObjectEmpty,
  getItemById,
} from '../../../utility/helpers';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import { validateFreemiumAction } from '../../../utility/subscriptionHelper';
import {
  API_ATTRIBUTES,
  api_duplicate_attribute,
  api_attribute_usage,
} from '../../../constants/apiRoutes';
import {
  PARAMS,
  PAGE_WHITELISTED_PARAMS,
  ATTRIBUTES_DEFAULT_PARAMS,
} from '../../../constants/pages';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';
import { sticky } from '../../../constants/helperCssRules';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { FREEMIUM_LIMIT_TYPES } from '../../../constants/appConfig';
import {
  INIT_SORT,
  ATTRIBUTE_NAME_FIELD,
  ATTRIBUTE_SHORT_NAME_FIELD,
  SAVE,
  QUESTIONS_ACTIONS,
  getNewAttributeData,
  getTableHeaders,
  prepareTableData,
  getHeaderActions,
  getAttributeFields,
  getAttributeQuestionsFields,
  getPageFilters,
  getAttributeStatuses,
  getAttributeTypes,
  getAttributeDeleteTranslations,
  isAttributeManageable,
} from './config';

const styles = ({ breakpoints, palette: { primary }, spacing }) => ({
  form: {
    display: 'grid',
    gridColumnGap: 24,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateAreas: `
      "name code"
      "types tags"
      "isPrivate isActive"
      "color createdBy"
      "description description"
    `,
  },
  name: {
    gridArea: 'name',
  },
  code: {
    gridArea: 'code',
  },
  attributeTypes: {
    gridArea: 'types',
  },
  tags: {
    gridArea: 'tags',
  },
  isPrivate: {
    gridArea: 'isPrivate',
  },
  isActive: {
    gridArea: 'isActive',
    width: '100%',
  },
  color: {
    gridArea: 'color',
  },
  createdBy: {
    gridArea: 'createdBy',
  },
  description: {
    gridArea: 'description',
    '& .ql-container': {
      height: 376,
    },
    '& > div': {
      height: 445,
    },
  },
  stickyHeader: {
    ...sticky(primary.white, 105),
    width: '100%',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing(4),
  },
  pageDescription: {
    padding: spacing(8, 0, 6, 0),
  },
  rightSide: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  search: {
    width: 234,
  },
  addAttribute: {
    marginLeft: spacing(2),
    width: 'max-content',
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    '& .grid-table-header': {
      ...sticky(primary.bluish8, 235),
    },

    '& .grid-table-header$readMoreActive': {
      top: 289,
    },

    '& .actions-dots-menu': {
      padding: spacing(3, 1),
      overflow: 'visible',

      [breakpoints.up('xLg')]: {
        padding: spacing(3),
      },
    },
  },
  readMoreActive: {},
  questionsMenu: {
    cursor: 'pointer',
  },
  tooltipText: {
    lineHeight: '20px',
    color: primary.bluish2,
  },
  privateAttribute: {
    display: 'flex',
  },
  tableActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  viewReport: {
    marginRight: spacing(2),
  },
  reportIcon: {
    width: 16,
    height: 16,
  },
});

const { PAGE, PAGE_SIZE, ORDERING, SEARCH, IS_ACTIVE, TYPE, TAGS } = PARAMS;

class AttributesPage extends PureComponent {
  state = {
    isInitialLoad: true,
    isLoading: false,
    page: 1,
    attributeId: null,
    attributeName: '',
    attributeShortName: '',
    attributeUsedCount: 0,
    assignedPositions: {},
    confirmDeleteAttributeMessage: '',
    filters: {
      [IS_ACTIVE]: [],
      [TYPE]: [],
      [TAGS]: [],
    },
    search: '',
    ordering: INIT_SORT,
    isReadMore: true,
    isCreateDrawerOpen: false,
    isQuestionsDrawerOpen: false,
    isEditAttribute: false,
    attributeData: {},
    attributeTimesUsed: null,
    attributeQuestionNumber: null,
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearAttributes,
      clearAttribute,
      clearTagsCategories,
      clearPageQuickFilters,
    } = this.props;

    clearAttributes();
    clearAttribute();
    clearTagsCategories();
    clearPageQuickFilters();
  }

  handlePageChange = (isLoadMore = false, pageSize = 0) => {
    const { history, location, getAttributes } = this.props;
    const { page, filters, ordering, search } = this.state;
    const params = {
      page,
      [ORDERING]: ordering.sortKey,
      ...filters,
      ...(search ? { search } : {}),
    };
    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/attributes/?${query}`);
    }

    return getAttributes(isLoadMore, {
      ...(pageSize ? { ...params, [PAGE_SIZE]: pageSize } : params),
    });
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, [PAGE]: prevState[PAGE] + 1 }),
      () => this.handlePageChange(true)
    );
  };

  onSearch = text => {
    const searchTerm = trimString(text);

    this.setState(
      {
        [PAGE]: ATTRIBUTES_DEFAULT_PARAMS[PAGE],
        [SEARCH]: searchTerm,
        isLoading: true,
      },
      () =>
        this.handlePageChange().then(() => this.setState({ isLoading: false }))
    );
  };

  onSort = newOrdering => {
    this.setState(
      { [PAGE]: ATTRIBUTES_DEFAULT_PARAMS[PAGE], [ORDERING]: newOrdering },
      () => this.handlePageChange()
    );
  };

  handleApplyFilters = filters => {
    this.setState(
      { [PAGE]: ATTRIBUTES_DEFAULT_PARAMS[PAGE], filters, isLoading: true },
      () =>
        this.handlePageChange().then(() => this.setState({ isLoading: false }))
    );
  };

  onAddAttribute = () => this.setState({ isCreateDrawerOpen: true });

  onEditClick = async attribute => {
    const { getAttribute } = this.props;

    await getAttribute(attribute.id);

    this.setState({
      isCreateDrawerOpen: true,
      isEditAttribute: true,
      attributeData: {
        ...this.props.attribute,
        tags: getObjectToNumberArray(this.props.attribute.tags),
      },
      attributeId: attribute.id,
      attributeTimesUsed: attribute.times_used,
    });
  };

  onCloseCreateDrawer = () => {
    const { clearAttribute } = this.props;

    this.setState({
      isCreateDrawerOpen: false,
      isEditAttribute: false,
      attributeData: {},
      attributeId: null,
      attributeTimesUsed: null,
    });
    clearAttribute();
  };

  onCloseQuestionsDrawer = () => {
    const { clearAttribute, attribute } = this.props;
    const { attributeQuestionNumber } = this.state;

    if (
      attributeQuestionNumber !== null &&
      attributeQuestionNumber !== attribute.questions.length
    ) {
      this.handlePageChange();
    }
    this.setState({
      isQuestionsDrawerOpen: false,
      attributeId: null,
      attributeQuestionNumber: null,
    });
    clearAttribute();
  };

  onPreview = row => {
    const { getAttribute, setDialogVisibility } = this.props;

    return getAttribute(row.id).then(() => {
      setDialogVisibility({
        dialogName: 'previewAttributeDialog',
        opened: true,
      });
    });
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  onConfirmDeleteAttribute(id) {
    const { translations } = this.props;
    return http.delete(`${API_ATTRIBUTES}${id}/`).then(() => {
      this.setState({ [PAGE]: ATTRIBUTES_DEFAULT_PARAMS[PAGE] }, () =>
        this.handlePageChange()
      );
      this.closeDeleteAttributeDialog();
      this.onCloseCreateDrawer();
      this.onCloseQuestionsDrawer();
      showSuccessMessage(translations.deleteAttributeDialog.deleteSuccess);
    }, this.closeDeleteAttributeDialog);
  }

  onDuplicate = attribute => {
    const { setDialogVisibility } = this.props;

    return this.setState(
      {
        attributeId: attribute.id,
        attributeName: attribute.name,
        attributeShortName: attribute.code,
      },
      () => {
        setDialogVisibility({
          dialogName: 'chooseNameDialog',
          opened: true,
        });
      }
    );
  };

  onCloseDuplicate = () => {
    const { setDialogVisibility } = this.props;
    this.setState({
      attributeId: null,
      attributeName: '',
      attributeShortName: '',
    });
    setDialogVisibility({
      dialogName: 'chooseNameDialog',
      opened: false,
    });
  };

  onDuplicateAttribute = async (
    attributeId,
    attributeName,
    attributeShortName
  ) => {
    try {
      await http.post(api_duplicate_attribute(attributeId), {
        name: attributeName,
        code: attributeShortName,
      });
      this.setState({ [PAGE]: ATTRIBUTES_DEFAULT_PARAMS[PAGE] }, () =>
        this.handlePageChange()
      );
    } finally {
      this.onCloseDuplicate();
    }
  };

  getInitialData = () => {
    const { location, translations, getTagsCategories, getPageQuickFilters } =
      this.props;
    const columns = getTableHeaders(translations.columns);
    const params = parseQueryParams(
      location.search,
      PAGE_WHITELISTED_PARAMS.ATTRIBUTES
    );

    if (!isObjectEmpty(params)) {
      const [ascending, descending] = params[ORDERING].split('-');
      const isAscending = !!ascending;

      return this.setState(
        {
          [ORDERING]: {
            column: getItemById(
              columns,
              isAscending ? ascending : descending,
              'sortAs'
            )?.rowKey,
            asc: isAscending,
            sortKey: params[ORDERING],
          },
          [SEARCH]: params[SEARCH] || '',
          filters: {
            [IS_ACTIVE]: params[IS_ACTIVE] ? [params[IS_ACTIVE]] : [],
            [TYPE]: params[TYPE] ? [params[TYPE]] : [],
            [TAGS]: params[TAGS]
              ? [...(isArray(params[TAGS]) ? params[TAGS] : [params[TAGS]])]
              : [],
          },
        },
        () => {
          return Promise.all([
            this.handlePageChange(
              false,
              ATTRIBUTES_DEFAULT_PARAMS[PAGE_SIZE] * params[PAGE]
            ),
            getTagsCategories(),
            getPageQuickFilters(),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      this.handlePageChange(
        false,
        ATTRIBUTES_DEFAULT_PARAMS[PAGE_SIZE] * params[PAGE]
      ),
      getTagsCategories(),
      getPageQuickFilters(),
    ]).then(() => {
      this.setState({ isInitialLoad: false });
    });
  };

  openAddQuestionsDrawer = async attributeData => {
    const { getAttribute } = this.props;

    await getAttribute(attributeData.id).then(() => {
      this.setState({
        isCreateDrawerOpen: false,
        attributeData: {},
        isQuestionsDrawerOpen: true,
        attributeId: attributeData.id,
        attributeQuestionNumber: attributeData.question_number,
      });
    });
  };

  onCreateAttribute = attributeData => {
    const saveType = attributeData.save_type;
    delete attributeData.save_type;
    const updatedData = {
      ...attributeData,
      description: attributeData.description || undefined,
    };
    const { AddNewAttribute } = EVENT_ACTION_TYPES;
    return http.post(API_ATTRIBUTES, updatedData).then(({ data }) => {
      tagManagerDataLayer(
        AddNewAttribute.action,
        AddNewAttribute.name,
        attributeData.name
      );
      if (saveType === SAVE) {
        this.setState({
          isCreateDrawerOpen: false,
        });
        return this.handlePageChange();
      }
      const trimmedData = { id: data.id, question_number: null };
      this.openAddQuestionsDrawer(trimmedData).then(() => {
        this.handlePageChange();
      });
    });
  };

  onAttributeEditSave = updatedData => {
    const { updateAttribute } = this.props;
    const isSaveType = updatedData.save_type === SAVE;
    const { isDirty } = updatedData;

    delete updatedData.save_type;
    delete updatedData.isDirty;
    const attributeInfo = {
      ...updatedData,
      description: updatedData.description || null,
    };

    if (isDirty) {
      return updateAttribute(updatedData.id, attributeInfo).then(() => {
        if (isSaveType) {
          this.setState({
            isCreateDrawerOpen: false,
            attributeData: {},
            attributeId: null,
            attributeTimesUsed: null,
          });
          return this.handlePageChange();
        }
        this.openAddQuestionsDrawer(updatedData).then(() => {
          this.handlePageChange();
        });
      });
    }
    if (!isSaveType) {
      return this.openAddQuestionsDrawer(updatedData);
    }
    this.setState({
      isCreateDrawerOpen: false,
      attributeData: {},
      attributeId: null,
      attributeTimesUsed: null,
    });
    return Promise.resolve();
  };

  getMenuItems = (isManageable, attribute, questionOption, hasPreview) => {
    const {
      translations,
      organizationSettings,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const actionName = questionOption.name;

    return [
      {
        type: 'edit',
        label: translations.menuItemLabels.edit,
        shouldItemRender: () => isManageable,
        action: () => this.onEditClick(attribute),
        icon: EditIcon,
      },
      {
        type: 'duplicate',
        label: translations.menuItemLabels.duplicate,
        shouldItemRender: () => true,
        action: () =>
          validateFreemiumAction(
            this.onDuplicate,
            FREEMIUM_LIMIT_TYPES.ATTRIBUTES,
            organizationSettings,
            setDialogVisibility,
            getOrganizationSettings
          )(attribute),
        icon: DuplicateIcon,
      },
      {
        type: 'delete',
        label: translations.menuItemLabels.delete,
        shouldItemRender: () => isManageable,
        action: () => {
          this.setState(
            {
              attributeId: attribute.id,
            },
            () => {
              this.onDeleteAttribute({ id: attribute.id });
            }
          );
        },
        icon: DeleteIcon,
      },
      {
        type: translations.menuItemLabels[actionName],
        label: translations.menuItemLabels[actionName],
        shouldItemRender: () => !hasPreview,
        action: () => this.handleQuestionsClick(attribute),
        icon: questionOption.icon,
      },
    ];
  };

  onDeleteAttribute = ({ id }) => {
    const { setDialogVisibility } = this.props;
    const attributeId = id;

    http.get(api_attribute_usage(attributeId)).then(({ data }) => {
      if (!isArrayEmpty(data.positions)) {
        this.setState(
          {
            attributeId,
            assignedPositions: {
              count: data.positions.length,
              results: [...data.positions],
            },
          },
          () => {
            setDialogVisibility({
              dialogName: 'warningDialog',
              opened: true,
            });
          }
        );
      } else {
        this.setState(
          {
            attributeId,
            attributeUsedCount: data.inquiries.length,
          },
          () => {
            setDialogVisibility({
              dialogName: 'confirmAttributeDeletion',
              opened: true,
            });
          }
        );
      }
    });
  };

  closeDeleteAttributeDialog = () => {
    const { setDialogVisibility } = this.props;

    this.setState(
      {
        attributeId: null,
        attributeUsedCount: 0,
        assignedPositions: {},
      },
      () =>
        setDialogVisibility({
          dialogName: 'confirmAttributeDeletion',
          opened: false,
        })
    );
  };

  onCloseWarningDialog = () => {
    const { setDialogVisibility } = this.props;
    this.setState({ assignedPositions: {}, attributeId: null }, () => {
      setDialogVisibility({
        dialogName: 'warningDialog',
        opened: false,
      });
    });
  };

  handleToggleReadMore = () => {
    this.setState(prevState => ({
      isReadMore: !prevState.isReadMore,
    }));
  };

  renderActions = (isManageable, attribute, isAdmin) => {
    const { classes, translations } = this.props;
    const questionOption = this.chooseQuestionOption(isManageable, attribute);
    const hasPreview = questionOption.name === QUESTIONS_ACTIONS.PREVIEW.name;

    return (
      <div className={classes.tableActions}>
        {attribute.question_number ? (
          <ActionButton
            type={ACTION_BUTTON_TYPES.PREVIEW}
            tooltipText={
              translations.menuItemLabels[QUESTIONS_ACTIONS.PREVIEW.name]
            }
            onClickHandler={() => this.onPreview(attribute)}
          />
        ) : null}
        {isAdmin && attribute.times_used ? (
          <ActionButton
            className={classes.viewReport}
            iconClass={classes.reportIcon}
            type={ACTION_BUTTON_TYPES.ATTRIBUTE_REPORT}
            tooltipText={translations.viewReport}
            linkProps={{
              to: `/reports/attribute`,
              state: { attribute },
            }}
            isLink
          />
        ) : null}
        <DotsMenu
          className={classes.dotsMenu}
          menuItems={this.getMenuItems(
            isManageable,
            attribute,
            questionOption,
            hasPreview
          )}
        />
      </div>
    );
  };

  chooseQuestionOption = (isAdmin, attribute) => {
    const { auth } = this.props;
    const canManageAttribute = isAttributeManageable(isAdmin, auth, attribute);

    if (canManageAttribute && attribute.is_editable) {
      if (attribute.question_number === 0) {
        return QUESTIONS_ACTIONS.CREATE;
      }
      return QUESTIONS_ACTIONS.EDIT;
    }
    return QUESTIONS_ACTIONS.PREVIEW;
  };

  handleQuestionsClick = attribute => {
    const { getAttribute } = this.props;
    getAttribute(attribute.id).then(() => {
      this.setState({
        isQuestionsDrawerOpen: true,
        attributeId: attribute.id,
        attributeQuestionNumber: attribute.question_number,
      });
    });
  };

  handleCreateQuestion = questionData => {
    const { createQuestion } = this.props;

    return createQuestion(questionData).then(() => {
      this.handlePageChange();
    });
  };

  renderTooltipText = createdBy => {
    const { classes, translations } = this.props;

    return (
      <Typography variant="body2" className={classes.tooltipText}>
        {getPersonalAttributeTooltipText(
          translations.personalAttributeTooltip,
          createdBy
        )}
      </Typography>
    );
  };

  renderPersonalAttribute = createdBy => {
    const { classes, translations } = this.props;

    return (
      <div className={classes.privateAttribute}>
        <ArrowTooltip
          position="top"
          tooltipLabel={this.renderTooltipText(createdBy)}
        >
          <Typography>{translations.personal}</Typography>
        </ArrowTooltip>
      </div>
    );
  };

  render() {
    const {
      translations,
      classes,
      attributes,
      categories,
      dialogs,
      setDialogVisibility,
      attribute,
      auth,
      organizationSettings,
      setAttribute,
      reorderQuestions,
      deleteAllAttributeQuestions,
      updateAttribute,
      clearAttribute,
      setAttributeQuestions,
      updateAttributeQuestions,
      updateAttributeQuestion,
      deleteAttributeQuestion,
      getAttribute,
      pageQuickFilters,
      getOrganizationSettings,
    } = this.props;

    const {
      isInitialLoad,
      attributeId,
      attributeName,
      attributeShortName,
      isLoading,
      assignedPositions,
      ordering,
      filters,
      isReadMore,
      isCreateDrawerOpen,
      isQuestionsDrawerOpen,
      isEditAttribute,
      attributeData,
      attributeUsedCount,
      attributeTimesUsed,
      search,
    } = this.state;
    const {
      confirmAttributeDeletionOpened,
      chooseNameDialogOpened,
      previewAttributeDialogOpened,
      warningDialogOpened,
    } = dialogs;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const statuses = getAttributeStatuses(translations);
    const types = getAttributeTypes(translations.attributeTypes);
    const attributeFilters = [{ id: null, name: translations.all }, ...types];
    const showLoadMore = !!attributes.next;
    const filterIsActive = hasSelectedFilters(filters);

    return (
      !isInitialLoad && (
        <div>
          <div className={classes.stickyHeader}>
            <div className={classes.pageDescription}>
              <ReadLessMore
                translations={translations}
                isReadMore={isReadMore}
                toggleReadMore={this.handleToggleReadMore}
              >
                <Typography variant="body2">
                  {translations.descriptionPartOne}
                </Typography>
                <Typography variant="body2">
                  {translations.descriptionPartTwo}
                </Typography>
                <Typography variant="body2">
                  {translations.descriptionPartThree}
                </Typography>
              </ReadLessMore>
            </div>
            <div className={classes.actionContainer}>
              <div className={classes.filters}>
                <Filters
                  translations={translations.filters}
                  selectedFilters={filters}
                  filters={getPageFilters(
                    translations.filters.labels,
                    statuses,
                    attributeFilters,
                    categories,
                    pageQuickFilters
                  )}
                  onApplyFilters={this.handleApplyFilters}
                />
              </div>
              <div className={classes.rightSide}>
                <Search
                  className={classes.search}
                  placeholder={translations.searchPlaceholder}
                  value={search}
                  onChange={this.onSearch}
                />
                <CustomButton
                  className={classes.addAttribute}
                  type="addRoundedNew"
                  onClick={validateFreemiumAction(
                    this.onAddAttribute,
                    FREEMIUM_LIMIT_TYPES.ATTRIBUTES,
                    organizationSettings,
                    setDialogVisibility,
                    getOrganizationSettings
                  )}
                >
                  {translations.addAttribute}
                </CustomButton>
              </div>
            </div>
          </div>
          <div>
            {isArray(attributes.results) &&
              !isArrayEmpty(attributes.results) && (
                <GridTable
                  className={classes.table}
                  translations={translations}
                  customHeaderClass={classNames({
                    [classes.readMoreActive]: !isReadMore,
                  })}
                  initialSort={ordering}
                  headers={getTableHeaders(translations.columns)}
                  headerActions={getHeaderActions(translations.columns)}
                  hasLoadMore={showLoadMore}
                  rows={prepareTableData(
                    translations,
                    auth,
                    isAdmin,
                    attributes.results,
                    types,
                    this.renderActions,
                    this.renderPersonalAttribute
                  )}
                  onLoadMore={this.onLoadMore}
                  onSort={this.onSort}
                />
              )}
            {!isLoading &&
              isArray(attributes.results) &&
              isArrayEmpty(attributes.results) && (
                <NotificationCard
                  title={
                    search || filterIsActive ? '' : translations.noResultsTitle
                  }
                  content={
                    search || filterIsActive
                      ? translations.noSearchResultsMessage
                      : translations.noResultsMessage
                  }
                />
              )}
          </div>
          <AlertDialog
            isOpened={confirmAttributeDeletionOpened}
            translations={getAttributeDeleteTranslations(
              translations,
              confirmAttributeDeletionOpened,
              attributeUsedCount
            )}
            onClose={this.closeDeleteAttributeDialog}
            onConfirm={() => {
              this.onConfirmDeleteAttribute(attributeId);
            }}
            isWarning
          />
          <WarningDialog
            translations={translations.attributeDeleteWarningDialog}
            isOpened={warningDialogOpened}
            items={assignedPositions.results || []}
            onClose={this.onCloseWarningDialog}
          />
          <ChooseNameDialog
            translations={translations.chooseNameDialog}
            title={translations.chooseNameDialog.title}
            description={translations.chooseNameDialog.description}
            isOpened={chooseNameDialogOpened}
            initialValue={attributeName}
            field={ATTRIBUTE_NAME_FIELD}
            onSave={(name, shortName) =>
              this.onDuplicateAttribute(attributeId, name, shortName)
            }
            onCancel={() => {
              this.setState({
                attributeId: null,
                attributeName: '',
                attributeShortName: '',
              });
              setDialogVisibility({
                dialogName: 'chooseNameDialog',
                opened: false,
              });
            }}
            shortNameField={ATTRIBUTE_SHORT_NAME_FIELD}
            initialShortNameValue={attributeShortName}
            withShortNameInput
          />
          <PreviewAttributeDialog
            isOpened={previewAttributeDialogOpened}
            translations={translations.previewAttributeDialog}
            attribute={attribute}
            onClose={() => {
              clearAttribute();
              setDialogVisibility({
                dialogName: 'previewAttributeDialog',
                opened: false,
              });
            }}
          />
          <CustomFormDrawer
            customFormClass={classes.form}
            translations={translations.addAttributeDrawerForm}
            titleText={
              isEditAttribute
                ? attributeData.name
                : translations.addAttributeDrawerForm.add
            }
            initialData={
              isEditAttribute ? attributeData : getNewAttributeData(auth)
            }
            isOpened={isCreateDrawerOpen}
            isInitialValid={isEditAttribute}
            fields={getAttributeFields(this.onCreateTag, classes)}
            attributeTypes={types}
            categories={categories}
            hasAddButton={!attributeTimesUsed}
            onDelete={this.onDeleteAttribute}
            onClose={this.onCloseCreateDrawer}
            onSave={
              isEditAttribute
                ? this.onAttributeEditSave
                : this.onCreateAttribute
            }
            hasCancelButton
            isMiddleWidth
          />
          <CustomFormDrawer
            translations={translations.addAttributeDrawerForm}
            titleText={attribute.name}
            initialData={{
              ...attribute,
            }}
            isOpened={isQuestionsDrawerOpen}
            fields={getAttributeQuestionsFields(
              translations.addAttributeDrawerForm.formLabels
            )}
            dialogs={dialogs}
            questionsLimit={organizationSettings.question_limit}
            createQuestion={this.handleCreateQuestion}
            setDialogVisibility={setDialogVisibility}
            setAttribute={setAttribute}
            reorderQuestions={reorderQuestions}
            deleteAllAttributeQuestions={deleteAllAttributeQuestions}
            updateAttribute={updateAttribute}
            updateAttributeQuestion={updateAttributeQuestion}
            setAttributeQuestions={setAttributeQuestions}
            updateAttributeQuestions={updateAttributeQuestions}
            deleteAttributeQuestion={deleteAttributeQuestion}
            getAttribute={getAttribute}
            onClose={this.onCloseQuestionsDrawer}
            onDelete={this.onDeleteAttribute}
            isInitialValid
            isMiddleWidth
            isFinish
          />
        </div>
      )
    );
  }
}

AttributesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  attribute: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageQuickFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dialogs: PropTypes.object.isRequired,
  getAttribute: PropTypes.func.isRequired,
  clearAttribute: PropTypes.func.isRequired,
  clearAttributes: PropTypes.func.isRequired,
  updateAttributeQuestion: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  setAttributeQuestions: PropTypes.func.isRequired,
  updateAttributeQuestions: PropTypes.func.isRequired,
  deleteAttributeQuestion: PropTypes.func.isRequired,
  updateAttribute: PropTypes.func.isRequired,
  deleteAllAttributeQuestions: PropTypes.func.isRequired,
  reorderQuestions: PropTypes.func.isRequired,
  createQuestion: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  setAttribute: PropTypes.func.isRequired,
  getAttributes: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  getPageQuickFilters: PropTypes.func.isRequired,
  clearPageQuickFilters: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};

export default withStyles(styles)(AttributesPage);
