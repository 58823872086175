import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import HorizontalNavigation from '../horizontalNavigation';
import UserMenu from '../userMenu';
import { setAuthInfo } from '../../../store/modules/auth';
import StorageService from '../../../utility/storageService';
import http, { getHeaders } from '../../../utility/http';
import { showErrorMessage } from '../../../utility/uiUtils';
import { getUserMenuItems } from './config';
import { sticky } from '../../../constants/helperCssRules';
import { API_CUSTOMER_PORTAL } from '../../../constants/apiRoutes';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    borderBottom: `1px solid ${primary.bluish8}`,
    ...sticky(primary.white),
  },
  mainPerson: {
    borderBottom: 'none',
  },
  header: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: spacing(8, 0, 6, 0),
    width: '100%',
  },
  welcomeTitle: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(4),
  },
}));

const PageHeader = ({
  translations,
  navigate,
  location,
  user,
  title,
  navItems,
  renderTitle,
  organizationSettings,
  isPremiumUser,
  grantedPermissions,
  onGoToProfile,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const hasNavigation = !isArrayEmpty(navItems);

  const onManageSubscription = () => {
    http
      .post(API_CUSTOMER_PORTAL, {
        return_url: `${window.location.origin}${window.location.pathname}`,
      })
      .then(({ data }) => {
        if (data.customer_portal_url) {
          window.location.href = data.customer_portal_url;
        } else if (data.stripe_customer) {
          showErrorMessage(data.stripe_customer);
        } else {
          showErrorMessage('Something goes wrong.');
        }
      });
  };

  const onLogout = () => {
    StorageService.clear();
    http.defaults.headers = getHeaders(StorageService);
    dispatch(setAuthInfo({}));
    navigate('/login');
  };

  const onRedirect = url => () => navigate(url);

  const userMenuItems = getUserMenuItems(
    translations.userMenu,
    location.pathname,
    grantedPermissions,
    organizationSettings,
    isPremiumUser,
    onRedirect,
    onLogout,
    onManageSubscription
  );

  return (
    <div
      className={classNames(classes.main, {
        [classes.mainPerson]: hasNavigation,
      })}
    >
      <div className={classes.header}>
        {title ? (
          <Typography variant="h1" className={classes.welcomeTitle}>
            <span>{title}</span>
          </Typography>
        ) : (
          renderTitle()
        )}
        <UserMenu
          translations={translations}
          user={user}
          organizationSettings={organizationSettings}
          menuItems={userMenuItems}
          onGoToProfile={onGoToProfile}
        />
      </div>
      {hasNavigation && <HorizontalNavigation navItems={navItems} />}
    </div>
  );
};

PageHeader.defaultProps = {
  title: '',
  translations: {},
  navItems: [],
  renderTitle: () => {},
};

PageHeader.propTypes = {
  translations: PropTypes.object,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  navItems: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.object.isRequired,
  title: PropTypes.string,
  renderTitle: PropTypes.func,
  onGoToProfile: PropTypes.func.isRequired,
};

export default PageHeader;
