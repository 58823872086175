import { useState, useMemo, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';
import UserAvatar from '../userAvatar';
import SelectField from '../selectField';
import CustomScrollBar from '../customScrollBar';
import ManageUserTagsDialog from '../manageUserTagsDialog';
import {
  isArrayEmpty,
  isObjectEmpty,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import http from '../../../utility/http';
import { useAvailableUsers } from '../../../utility/hooks';
import {
  isPremiumUserCheck,
  isSubscriptionExpiredCheck,
} from '../../../utility/subscriptionHelper';
import { validateFields } from '../../../utility/validation';
import { UserStatuses } from '../../../constants/statuses';
import { PARAMS } from '../../../constants/pages';
import { USER_INFO } from '../../../constants/people';
import { API_SUBSCRIPTION } from '../../../constants/apiRoutes';
import { BULK_ACTION_OPTIONS_VALUES } from '../../../constants/bulkActionOptions';
import { FREEMIUM_LIMIT_TYPES } from '../../../constants/appConfig';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { fieldsToShow, getUsersToHandle } from './config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  usersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 186,
    marginBottom: spacing(4),
  },
  avatars: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  userAvatar: {
    width: '100%',
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  fields: {
    position: 'relative',
  },
  field: {
    marginBottom: spacing(6),
  },
  hasError: {
    marginBottom: spacing(1),
  },
  inviteMessage: {
    marginBottom: spacing(4),
  },
  usersToInvite: {
    marginTop: spacing(1),
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -20,
    height: '100%',
    width: 8,
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
}));

const BulkEditDialog = props => {
  const {
    translations,
    isOpened,
    selectedBulkAction,
    organizationUser,
    organizationSettings,
    users,
    idleUsers,
    categories,
    getOrganizationSettings,
    onCancel,
    onSave,
  } = props;
  const [, Invited] = UserStatuses;
  const classes = useStyles();

  const [selected, setSelected] = useState({
    jobTitle: null,
    reportTo: null,
    role: null,
    track: null,
    level: null,
  });
  const [errors, setErrors] = useState({});

  const isChangeReport = useMemo(
    () =>
      selectedBulkAction.value === BULK_ACTION_OPTIONS_VALUES.changeReportingTo,
    [selectedBulkAction]
  );

  const params = useMemo(() => {
    if (!isOpened) return {};

    return isChangeReport && !isArrayEmpty(users)
      ? {
          [PARAMS.AVAILABLE_MANAGERS]: getObjectToNumberArray(users),
        }
      : {};
  }, [isOpened, users, isChangeReport]);

  const availableManagers = useAvailableUsers(params, organizationUser);

  const isInviteDisabled = useMemo(() => isArrayEmpty(idleUsers), [idleUsers]);
  const isInvite = useMemo(
    () => isArrayEmpty(fieldsToShow[selectedBulkAction.value]),
    [selectedBulkAction]
  );

  const getOptionsByField = useCallback(
    field => {
      if (field.options) {
        if (field.parent) {
          const parentValue = selected[field.parent.name];
          if (!parentValue) {
            return [];
          }
          const parent = props[field.parent.options].find(
            elem => elem.id === parentValue
          );

          return (parent && parent[field.options]) || [];
        }
        return props[field.options] || [];
      }
      return [];
    },
    [props, selected]
  );

  const resetDependants = fieldDependants => {
    if (fieldDependants && !isArrayEmpty(fieldDependants)) {
      const dependantsValues = fieldDependants.reduce((acc, dependant) => {
        return { ...acc, [dependant.name]: dependant.value };
      }, {});

      return setSelected(prevState => ({
        ...prevState,
        ...dependantsValues,
      }));
    }
  };

  const resetState = () => {
    setSelected(prevSelected => ({
      ...prevSelected,
      ...{
        jobTitle: null,
        reportTo: null,
        role: null,
        track: null,
        level: null,
      },
    }));
    setErrors({});
  };

  const onCloseDialog = () => {
    resetState();
    onCancel();
  };

  const handleSaveTags = ({ tags }) => {
    if (isArrayEmpty(tags)) {
      return onCloseDialog();
    }

    const postData = users.reduce((acc, user) => {
      return [
        ...acc,
        {
          id: user.id,
          ...(tags ? { tags } : {}),
        },
      ];
    }, []);

    onSave(postData);
  };

  const handleSelectValue = (name, dependants) => value => {
    const updatedErrors = { ...errors };

    if (errors && errors[name]) {
      updatedErrors[name] = null;
    }

    setSelected(prevSelected => ({
      ...prevSelected,
      [name]: value,
    }));
    resetDependants(dependants);
    setErrors(prevErrors => ({ ...prevErrors, ...updatedErrors }));
  };

  const validateAndSubmit = async () => {
    const { JOB_TITLE, REPORT_TO, STATUS, TRACK_LEVEL } = USER_INFO;
    const { jobTitle, reportTo, role, track, level } = selected;
    const usersToHandle = getUsersToHandle(users, idleUsers, isInvite, role);
    let updatedErrors = {};

    const postData = usersToHandle.reduce((acc, user) => {
      return [
        ...acc,
        {
          id: user?.id,
          ...(jobTitle ? { [JOB_TITLE]: jobTitle } : {}),
          ...(reportTo ? { [REPORT_TO]: reportTo?.id } : {}),
          ...(isInvite ? { [STATUS]: Invited.id } : {}),
          ...(role ? { role } : {}),
          ...(track ? { track } : {}),
          ...(level ? { [TRACK_LEVEL]: level } : {}),
        },
      ];
    }, []);

    updatedErrors = await validateFields(
      fieldsToShow[selectedBulkAction.value],
      selected
    );
    if (
      role &&
      !updatedErrors.role &&
      !isArrayEmpty(usersToHandle) &&
      (role === ROLES.ADMIN || role === ROLES.MODERATOR)
    ) {
      const isPremiumUser = isPremiumUserCheck(organizationSettings);
      const isSubscriptionExpired =
        isSubscriptionExpiredCheck(organizationSettings);
      if (!isPremiumUser || (isPremiumUser && isSubscriptionExpired)) {
        const { data } = await http.get(API_SUBSCRIPTION);

        if (data.is_active) {
          await getOrganizationSettings();
        }

        if (
          !data.is_active &&
          ((role === ROLES.ADMIN &&
            data.usage[FREEMIUM_LIMIT_TYPES.ADMINS] + usersToHandle.length >
              data.limit[FREEMIUM_LIMIT_TYPES.ADMINS]) ||
            (role === ROLES.MODERATOR &&
              data.usage[FREEMIUM_LIMIT_TYPES.MODERATORS] +
                usersToHandle.length >
                data.limit[FREEMIUM_LIMIT_TYPES.MODERATORS]))
        ) {
          updatedErrors = {
            [USER_INFO.ROLE]: 'freemiumLimit',
          };
        }
      }
    }

    setErrors(updatedErrors);

    if (isObjectEmpty(updatedErrors)) {
      try {
        await onSave(postData);
      } finally {
        resetState();
      }
    }
  };

  const renderAvatars = () => {
    const usersToRender = isInvite ? idleUsers : users;

    return (
      <div className={classes.avatars}>
        {usersToRender.map(user => {
          return (
            <UserAvatar
              key={user.id}
              className={classes.userAvatar}
              user={user}
              variant="subtitle2"
              small
              caption
            />
          );
        })}
      </div>
    );
  };

  const renderListOfUsers = () =>
    !isArrayEmpty(users) && (
      <div className={classes.usersWrapper}>
        <CustomScrollBar
          customScrollBarYClass={classes.scrollY}
          customScrollClass={classes.scroll}
          passContentHeight
          verticalScroll
          removeScrollX
        >
          {renderAvatars()}
        </CustomScrollBar>
      </div>
    );

  const renderField = field => {
    const label = translations.fields[field.translationKey];
    const { placeholders } = translations.fields;
    const hasError = !!errors[field.name];
    const fieldClasses = classNames(classes.field, {
      [classes.hasError]: hasError,
    });
    const labelHelpData = field.labelHelp && {
      tooltipText: translations.labelHelpData[field.translationKey].text,
    };

    return (
      <div key={field.name} className={fieldClasses}>
        <SelectField
          errorClass={classes.error}
          name={field.name}
          label={label}
          placeholder={placeholders[field.translationKey]}
          labelHelp={labelHelpData}
          value={selected[field.name]}
          hasError={hasError}
          errorMessage={
            translations.fieldValidations[field.translationKey][
              errors[field.name]
            ] || ''
          }
          shouldReturnOption={field.shouldReturnOption}
          isUser={field.isUser}
          options={
            field.isReportTo ? availableManagers : getOptionsByField(field)
          }
          parser={field.parser}
          required={field.required}
          isDisabled={
            (field.parent && !selected[field.parent.name]) ||
            (!isChangeReport && isArrayEmpty(getOptionsByField(field)))
          }
          isSearchDisabled={field.isSearchDisabled}
          isClearable={field.isClearable}
          onChange={handleSelectValue(field.name, field.dependants)}
          isFullWidth
        />
      </div>
    );
  };

  const renderInviteUsersMessage = () => {
    const {
      following,
      invited,
      userText,
      usersText,
      userHas,
      usersHave,
      noInvitation,
    } = translations.inviteMessage;
    const numberOfUsersToInvite = idleUsers.length;
    const numberOfUsersNotToInvite = users.length - numberOfUsersToInvite;

    return (
      <div className={classes.inviteMessage}>
        <Typography>
          {numberOfUsersNotToInvite > 0 &&
            `${noInvitation}${numberOfUsersNotToInvite}${
              numberOfUsersNotToInvite === 1 ? userHas : usersHave
            }`}
        </Typography>
        <Typography className={classes.usersToInvite}>
          {numberOfUsersToInvite > 0 &&
            `${following}${
              numberOfUsersToInvite === 1 ? userText : usersText
            } ${invited}`}
        </Typography>
      </div>
    );
  };

  return selectedBulkAction.key === 'applyTags' ? (
    <ManageUserTagsDialog
      translations={translations}
      isOpened={isOpened}
      categories={categories}
      userAvatars={renderListOfUsers()}
      onCancel={onCloseDialog}
      onSave={handleSaveTags}
    />
  ) : (
    <CustomModal
      title={translations[selectedBulkAction.key]}
      confirmButtonLabel={
        isInvite ? translations.inviteUsers : translations.save
      }
      closeButtonLabel={translations.cancel}
      isOpened={isOpened}
      isConfirmDisabled={isInvite && isInviteDisabled}
      onClose={onCloseDialog}
      onConfirm={validateAndSubmit}
      isMedium
    >
      {isInvite && <div>{renderInviteUsersMessage()}</div>}
      {renderListOfUsers(isInvite)}
      <div className={classes.fields}>
        {fieldsToShow[selectedBulkAction.value]?.map(renderField)}
      </div>
    </CustomModal>
  );
};

BulkEditDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationSettings: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  jobTitles: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  isOpened: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationUser: PropTypes.shape({}).isRequired,
  selectedBulkAction: PropTypes.object.isRequired,
  idleUsers: PropTypes.array.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};

export default memo(BulkEditDialog);
