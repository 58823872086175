import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PageContainer from '../../components/shared/pageContainer';
import SurveyReportPage from '../../components/pages/surveyReportPage';
import { attributeSelector } from '../../store/selectors/attributeSelector';
import { surveySelector } from '../../store/selectors/surveySelector';
import { getAttribute, clearAttribute } from '../../store/modules/attribute';
import {
  getSurvey,
  getSurveyAttributes,
  clearSurvey,
  getReviewResults,
  getTextReplyReviewResults,
  clearReviewResults,
  getReviewParticipants,
  clearReviewParticipants,
  getReviewFeedback,
  clearReviewFeedback,
  clearSurveyAttributes,
} from '../../store/modules/survey';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../utility/tagManager';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';
import TextBoxWithTooltip from '../../components/shared/textBoxWithTooltip';
import SurveyIconInfoTooltip from '../../components/shared/surveyIconInfoTooltip';

const mapStateToProps = state => ({
  ...surveySelector(state),
  review: { ...attributeSelector(state).attribute },
});

const mapDispatchToProps = dispatch => ({
  getSurvey: surveyId => getSurvey(dispatch, surveyId),
  getSurveyAttributes: surveyId => getSurveyAttributes(dispatch, surveyId),
  getAttribute: attributeId => getAttribute(dispatch, attributeId),
  getReviewResults: (surveyId, attributeId) =>
    getReviewResults(dispatch, surveyId, attributeId),
  getReviewParticipants: (surveyId, attributeId) =>
    getReviewParticipants(dispatch, surveyId, attributeId),
  getTextReplyReviewResults: (surveyId, attributeId) =>
    getTextReplyReviewResults(dispatch, surveyId, attributeId),
  getReviewFeedback: (surveyId, attributeId) =>
    getReviewFeedback(dispatch, surveyId, attributeId),
  clearSurvey: () => dispatch(clearSurvey()),
  clearAttribute: () => dispatch(clearAttribute()),
  clearReviewResults: () => dispatch(clearReviewResults()),
  clearReviewFeedback: () => dispatch(clearReviewFeedback()),
  clearReviewParticipants: () => dispatch(clearReviewParticipants()),
  clearSurveyAttributes: () => dispatch(clearSurveyAttributes()),
});

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 260px)',
  },
  anonymous: {
    display: 'block',
  },
  infoIcon: {
    flexShrink: 0,
    '& svg': {
      marginRight: 0,
    },
  },
  sharedIcon: {
    '& svg': {
      width: 18,
      height: 18,
    },
  },
}));

const SurveyReportPageView = props => {
  const [isLoading, setIsLoading] = useState(true);
  const { surveyId } = useParams();
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.SURVEY_REPORT);

  const getSurveyData = async () => {
    const { VisitSDP } = EVENT_ACTION_TYPES;

    await props.getSurvey(surveyId);
    await props.getSurveyAttributes(surveyId);
    setIsLoading(false);
    tagManagerDataLayer(VisitSDP.action, VisitSDP.name);
  };

  useEffect(() => {
    getSurveyData();

    return () => {
      props.clearSurvey();
      props.clearReviewFeedback();
      props.clearReviewResults();
      props.clearAttribute();
      props.clearReviewParticipants();
      props.clearSurveyAttributes();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderReportHeader = () => {
    const { survey } = props;
    const { title, isAnonymous, isOneTime } = survey;

    return (
      <div className={classes.title}>
        {!isLoading && (
          <>
            <TextBoxWithTooltip variant="h1" text={title} />
            {isOneTime && (
              <SurveyIconInfoTooltip
                className={classes.infoIcon}
                tooltipText={translations.oneTimeSurvey}
                isOneTimeSurvey
              />
            )}
            {isAnonymous && (
              <SurveyIconInfoTooltip
                className={classNames(classes.anonymous, classes.infoIcon)}
                tooltipText={translations.anonymousSurvey}
              />
            )}
            {survey.report_shared_to && (
              <SurveyIconInfoTooltip
                className={classNames(classes.sharedIcon, classes.infoIcon)}
                tooltipText={translations.sharedSurvey}
                isShared
              />
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <PageContainer
      {...props}
      translations={translations}
      headerProps={{
        renderTitle: renderReportHeader,
      }}
      isFullWidthContent
    >
      {!isLoading ? <SurveyReportPage /> : <Fragment />}
    </PageContainer>
  );
};

SurveyReportPageView.propTypes = {
  getSurvey: PropTypes.func.isRequired,
  clearReviewFeedback: PropTypes.func.isRequired,
  clearReviewResults: PropTypes.func.isRequired,
  clearSurvey: PropTypes.func.isRequired,
  clearAttribute: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyReportPageView);
