import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import LoginFormLayout from './layout';
import { validateAll } from '../../../utility/validation';
import http, { AUTH, ACCESS_TOKEN, TOKEN_TYPE } from '../../../utility/http';
import { API_LOGIN, API_ME } from '../../../constants/apiRoutes';
import StorageService from '../../../utility/storageService';
import fields from './fields';
import { PERMISSIONS_LIST_BY_ROLE } from '../../../constants/rolesAndPermissionList';
import { mergeAccessibleProfilesForUser } from '../../../utility/uiUtils';

const LoginForm = withFormik({
  mapPropsToValues: () => ({ email: '', password: '' }),
  validate: (values, props) => {
    const errors = validateAll(fields, values, props.translations);

    if (values.email === '' && values.password === '') {
      errors.email = props.translations.invalidEmailOrPassword;
      errors.password = props.translations.invalidEmailOrPassword;
    }

    return errors;
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    http.post(API_LOGIN, values).then(
      response => {
        http.defaults.headers.Authorization = `${TOKEN_TYPE} ${response.data[ACCESS_TOKEN]}`;

        // fetch user by token
        http.get(API_ME).then(user => {
          const authInfo = {
            ...response.data,
            ...user.data,
            permissions: PERMISSIONS_LIST_BY_ROLE[user.data.role],
            accessibleProfiles: mergeAccessibleProfilesForUser(user.data),
          };
          props.setAuthInfo(authInfo);
          StorageService.set(AUTH, JSON.stringify(authInfo));
          props.getOrganizationSettings();
          props.getOrganizationMenuSetup();
          props.getAllPresets();
          const { Login } = EVENT_ACTION_TYPES;
          tagManagerDataLayer(Login.action, Login.name);

          setSubmitting(false);
          props.history.push('/');
        });
      },
      () => setSubmitting(false)
    );
  },
})(LoginFormLayout);

LoginForm.propTypes = {
  setAuthInfo: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
};

export default LoginForm;
